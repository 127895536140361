import CustomContainer from "../../components/common/CustomContainer"
import styles from "../../assets/styles/appointments.module.css"
import { Tab, Tabs } from "@mui/material"
import { CustomTabPanel, a11yProps } from "../../components/common/CustomTabPanel"
import { useState } from "react"
import UpComing from "../../components/myAppointments/UpComing"
import Completed from "../../components/myAppointments/Completed"
import Cancelled from "../../components/myAppointments/Cancelled"

const MyAppointment = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <div className={'HeadAndButton'}>
                    <h2>My appointments</h2>
                </div>
                <div className={styles.appointmentsMain}>
                    <Tabs value={value} onChange={handleChange} className="custumTabs">
                        <Tab label="Upcoming" {...a11yProps(0)} />
                        <Tab label="Completed" {...a11yProps(1)} />
                        <Tab label="Missed" {...a11yProps(2)} />
                    </Tabs>
                    <CustomTabPanel value={value} index={0}>
                        <UpComing  />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Completed />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <Cancelled />
                    </CustomTabPanel>
                </div>
            </div>
        </CustomContainer>
    )
}

export default MyAppointment