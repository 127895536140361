import styles from "../../assets/styles/layout.module.css"
import { Outlet } from 'react-router-dom'
import Sidebar from './Sidebar'
import SidebardHeader from './SidebardHeader'
import { useSelector } from 'react-redux'
import { RootState } from '../../utils/store'
const Layout = () => {
    const { sidebarToggle } = useSelector((state: RootState) => state.common)

    return (
        <div className={styles.dashboardLayout}>
            <Sidebar />
            <div className={`${styles.pageContainer} ${sidebarToggle ? styles.toggle : ''}`}>
                <SidebardHeader />
                <div className={styles.dashboard_mainRapper}>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default Layout