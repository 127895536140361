import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAppointmentApi } from "../../api/services";

interface AppointmentTypes {
  UpcomingAppointment: DoctorAppointmentTypes[];
  CompletedAppointment: DoctorAppointmentTypes[];
  CancelledAppointment: DoctorAppointmentTypes[];
  loading: boolean;
  
}

export const fetchUpcomingAppReducer = createAsyncThunk<
  AppointmentTypes["UpcomingAppointment"],
  { search: string; id: number }
>("appointment/fetchUpcomingAppReducer", async (data, thunkAPI) => {
  try {
    const result = await getAppointmentApi(data);
    if (result?.success) {
      return result?.formattedAppointments as AppointmentTypes["UpcomingAppointment"];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const fetchCompletedAppReducer = createAsyncThunk<
  AppointmentTypes["CompletedAppointment"],
  { search: string; id: number }
>("appointment/fetchCompletedAppReducer", async (data, thunkAPI) => {
  try {
    const result = await getAppointmentApi(data);
    if (result?.success) {
      return result?.formattedAppointments as AppointmentTypes["CompletedAppointment"];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const fetchCancelledAppReducer = createAsyncThunk<
  AppointmentTypes["CancelledAppointment"],
  { search: string; id: number }
>("appointment/fetchCancelledAppReducer", async (data, thunkAPI) => {
  try {
    const result = await getAppointmentApi(data);
    if (result?.success) {
      return result?.formattedAppointments as AppointmentTypes["CancelledAppointment"];
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState: AppointmentTypes = {
  UpcomingAppointment: [],
  CompletedAppointment: [],
  CancelledAppointment: [],
  loading: false,
};

const AppointmentSlice = createSlice({
  name: "appointment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUpcomingAppReducer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchUpcomingAppReducer.fulfilled,
      (
        state,
        action: PayloadAction<
          AppointmentTypes["UpcomingAppointment"] | undefined
        >
      ) => {
        state.UpcomingAppointment = action.payload ?? [];
        state.loading = false;
      }
    );
    builder.addCase(fetchUpcomingAppReducer.rejected, (state) => {
      state.loading = false;
      state.UpcomingAppointment = [];
    });

    builder.addCase(fetchCompletedAppReducer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchCompletedAppReducer.fulfilled,
      (
        state,
        action: PayloadAction<
          AppointmentTypes["CompletedAppointment"] | undefined
        >
      ) => {
        state.CompletedAppointment = action.payload ?? [];
        state.loading = false;
      }
    );
    builder.addCase(fetchCompletedAppReducer.rejected, (state) => {
      state.loading = false;
      state.CompletedAppointment = [];
    });
    builder.addCase(fetchCancelledAppReducer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchCancelledAppReducer.fulfilled,
      (
        state,
        action: PayloadAction<
          AppointmentTypes["CancelledAppointment"] | undefined
        >
      ) => {
        state.CancelledAppointment = action.payload ?? [];
        state.loading = false;
      }
    );
    builder.addCase(fetchCancelledAppReducer.rejected, (state) => {
      state.loading = false;
      state.CancelledAppointment = [];
    });
  },
});

export default AppointmentSlice;
