import React, { useState } from 'react'
import styles from "../../assets/styles/videocall.module.css"
import { DocIcon, HeartIcon, RxIcon } from '../../utils/functions/Icons'
import GeneratePrescription from './GeneratePrescription'
import VitalMonitoring from './VitalMonitoring'
import Documents from './Documents'
const VideoCallSidebar = () => {
    const [activeTab, setActiveTab] = useState('0')
    return (
        <div className={styles.sidebarMain}>
            <div className={styles.SLeftSec}>
                {
                    activeTab === '0' ?
                        <VitalMonitoring />
                        :
                        activeTab === '1' ?
                            <Documents />
                            :
                            <GeneratePrescription />
                }
            </div>
            <div className={styles.SRightSec}>
                <div className={`${styles.SIcons} ${activeTab === '0' ? styles.active: ''}`} onClick={() => setActiveTab('0')}>
                    <HeartIcon />
                </div>
                <div className={`${styles.SIcons} ${activeTab === '1' ? styles.active: ''}`} onClick={() => setActiveTab('1')}>
                    <DocIcon />
                </div>
                <div className={`${styles.SIcons} ${activeTab === '2' ? styles.active: ''}`} onClick={() => setActiveTab('2')}>
                    <RxIcon />
                </div>
            </div>
        </div>
    )
}

export default VideoCallSidebar