import CustomContainer from "../../components/common/CustomContainer"
import styles from "../../assets/styles/appointments.module.css"
import callNowImg from "../../assets/images/common/callnow.png"
import bookNowImg from "../../assets/images/common/booknow.png"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { getConnectTokenApi } from "../../utils/api/services"
import { toast } from "react-toastify"
import { setAuthConnectToken } from "../../utils/store/auth/AuthSlice"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../utils/store"
import ConfirmModal from "../../components/common/ConfirmModal"
import UpdateProfileModal from "../../components/chat/UpdateProfileModal"
import { allergiesReducer, healthProblemsReducer, MedicationsReducer, symptomsReducer } from "../../utils/store/profile/ProfileSlice"

const TalkToDoctor = () => {
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const [openProfileModal, setOpenProfileModal] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>()
    const { LoginData } = useSelector((state: RootState) => state.auth)

    const closeProfileModal = () => {
        setOpenProfileModal(false)
        handleCallNow();
    }

    const ConfirmUpdateProfile = async () => {
        handleCallNow();
    }

    const handleCallNow = async () => {
        try {
            const sendData = {
                "uuid": LoginData?.uuid,
                "role": "patient-app"
            }
            const result = await getConnectTokenApi(sendData);
            if (result?.access_token) {
                dispatch(setAuthConnectToken(result?.access_token))
                navigate('/CallNow')
            }
        } catch (error: any) {
            toast.error(error?.data?.errors || error?.message);
        }
    }

    const CloseConfirmModal = () => {
        setOpenConfirmModal(false)
        handleCallNow();
    }
    const confirmSubmit = () => {
        setOpenProfileModal(true)
        setOpenConfirmModal(false)
        dispatch(MedicationsReducer({ path: 'medications' }));
        dispatch(healthProblemsReducer({ path: 'healthProblems' }));
        dispatch(symptomsReducer({ path: 'symptoms' }));
        dispatch(allergiesReducer({ path: 'allergies' }));
    }

    const handleCallNowClick = () => {
        setOpenConfirmModal(true)
    }

    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <div className={'HeadAndButton'}>
                    <h2>Talk to Doctor</h2>
                </div>
                <div className={styles.TalkToDoctorMain}>
                    <div className="grid grid-cols-2 gap-10">
                        <div className={styles.talkTODocBox} onClick={handleCallNowClick}>
                            <img src={callNowImg} alt="call now" />
                            <h3>Call Now</h3>
                            <p>Talk to the expert doctor right now</p>
                        </div>
                        <div className={styles.talkTODocBox} onClick={() => navigate('/MyAppointments/book')}>
                            <img src={bookNowImg} alt="book now" />
                            <h3>Book an Appointment</h3>
                            <p>Schedule a online consultation with the specialist doctor</p>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmModal
                openConfirmModal={openConfirmModal}
                CloseConfirmModal={CloseConfirmModal}
                confirmSubmit={confirmSubmit}
                btnLoading={btnLoading}
                title={`Do you want to update your profile?`}
            />
            <UpdateProfileModal
                openProfileModal={openProfileModal}
                btnLoading={btnLoading}
                closeProfileModal={closeProfileModal}
                ConfirmUpdateProfile={ConfirmUpdateProfile}
            />
        </CustomContainer>
    )
}

export default TalkToDoctor