import React, { useEffect, useState } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import { FormGroup, InputAdornment, Pagination, TextField } from '@mui/material'
import { DocumentIcon, SearchIcon } from '../../utils/functions/Icons'
import styles from "../../assets/styles/teams.module.css"
import { useNavigate } from 'react-router-dom'
import { getFolderApi } from '../../utils/api/services'
import { toast } from 'react-toastify'
import Loader from '../../components/common/Loader'

interface folderDataTypes {
    folderName: string;
    folderType: string;
    id: number
}
const SavedDocument = () => {
    const [folderData, setFolderData] = useState<folderDataTypes[] | []>([])
    const [page, setPage] = useState(1);
    const [searchValue, setSearchValue] = useState('')
    const [loading, setLoading] = useState(false)
    const [totalPage, setTotalPage] = useState(0)
    const navigate = useNavigate();
    const handleView = (id: number) => {
        navigate('/SavedDocuments/view', {
            state: id
        })
    }
    const fetchFolderData = async (data: { search: string, page: number }) => {
        try {
            setLoading(true)
            const result = await getFolderApi(data);
            if (result?.success) {
                setLoading(false)
                const showRes = result?.folders?.folders?.filter((item: folderDataTypes) => item?.folderType === "1")
                setFolderData(showRes)
                setTotalPage(Math.ceil(result?.folders?.totalCount / 10))
            }
            else {
                setLoading(false)
                setFolderData([]);
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

    useEffect(() => {
        if (searchValue) {
            const cleanFunction = setTimeout(() => {
                fetchFolderData({ search: searchValue || '', page: page });
            }, 1000)
            return () => clearTimeout(cleanFunction)
        }
        else {
            fetchFolderData({ search: searchValue || '', page: page });
        }
    }, [searchValue, page]);

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <div className={'HeadAndButton'}>
                    <h2>Saved Documents</h2>
                </div>
                <div className={styles.SearchBoxMain}>
                    <FormGroup className="customFormGroup">
                        <TextField
                            className={`custumTextField TextFieldAll ${styles.InputStyle}`}
                            id="searchValue"
                            name="searchValue"
                            onChange={(e) => (setSearchValue(e.target.value), setPage(1))}
                            value={searchValue}
                            placeholder='Search by folder name or document'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormGroup>
                </div>
                {
                    loading ? <Loader />
                        :
                        <div className={styles.AllDocuments}>
                            <div className="grid grid-cols-5 gap-6">
                                {folderData.map((item, index) => {
                                    return (
                                        <div key={index} className={styles.DocumentIconAndcont} onClick={() => handleView(item?.id)}>
                                            <h3>{item?.folderName}</h3>
                                            <DocumentIcon />
                                        </div>
                                    )
                                })}
                            </div>
                            {
                                folderData?.length > 10 &&
                                <div className='flex justify-center items-center mt-4'>
                                    <Pagination
                                        count={totalPage}
                                        onChange={handlePageChange}
                                        page={page}
                                        className='custumPagination'
                                    />
                                </div>
                            }
                        </div>
                }
            </div>
        </CustomContainer>
    )
}

export default SavedDocument