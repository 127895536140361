import { AvailabiiltyIcon, CareTeamIcon, DashboardIcon, HelpIcon, InboxIcon, LabTestIcon, MedicalRecordIcon, MedicineIcon, MyAppointmentIcon, PatientIcon, PhoneIcon, RPMAlertIcon, SavedDocumentIcon } from "../../utils/functions/Icons";

const SidebarData: SidebarDataType[] = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        icon: DashboardIcon
    },
    {
        title: 'Patients',
        path: '/patient',
        icon: PatientIcon
    },
    {
        title: 'Appointments',
        path: '/appointments',
        icon: MyAppointmentIcon
    },
    {
        title: 'Inbox',
        path: '/inbox',
        icon: InboxIcon
    },
    {
        title: 'Availability',
        path: '/availability',
        icon: AvailabiiltyIcon
    },
    {
        title: 'Help',
        path: '/help',
        icon: HelpIcon
    },
];

export default SidebarData;