import React, { useEffect, useState } from 'react';
import CustomContainer from "../../components/common/CustomContainer";
import styles from "../../assets/styles/profile.module.css";
import avtarIcon from "../../assets/images/common/avtar.png";
import InputFile from "../../components/common/InputFile";
import { FormControl, FormControlLabel, FormGroup, FormLabel, InputAdornment, MenuItem, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableRow, TextField, TextareaAutosize, Typography } from "@mui/material";
import { calculateAge, handleKeyPress } from "../../utils/functions/common";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../utils/store';
import { allergiesReducer, familyRelationReducer, getCiyReducer, getStateReducer, handleOpenModal, healthProblemsReducer, MedicationsReducer, symptomsReducer, VaccinationReducer } from '../../utils/store/profile/ProfileSlice';
import { getProfileApi, updateProfileApi, uploadFileApi } from '../../utils/api/services';
import { toast } from 'react-toastify';
import GenerateAbhaId from '../../components/common/GenerateAbhaId';
import AutocompleteInput from '../../components/common/AutocompleteInput';
import BtnLoader from '../../components/common/BtnLoader';
import Loader from '../../components/common/Loader';
import { setIsProfileComplete } from '../../utils/store/auth/AuthSlice';

const Profile: React.FC = () => {
    const [btnLoading, setBtnLoading] = useState(false)
    const [formState, setFormState] = useState<FormState>({
        fullname: "",
        email: "",
        mobile: "",
        dateofbirth: null,
        age: "",
        gender: "",
        height: "",
        weight: "",
        bloodgroup: "",
        emergency_contact: "",
        city: "",
        state: "",
        zip_code: "",
        profile_image: "",
        abha_id: "",
        medications: [],
        medical_allergies: [],
        health_problems: [],
        past_operation_detail: "",
    })
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const { stateData, cityData } = useSelector((state: RootState) => state.profile);
 
    useEffect(() => {
        dispatch(VaccinationReducer({ path: 'vaccinations' }));
        dispatch(MedicationsReducer({ path: 'medications' }));
        dispatch(healthProblemsReducer({ path: 'healthProblems' }));
        dispatch(symptomsReducer({ path: 'symptoms' }));
        dispatch(allergiesReducer({ path: 'allergies' }));
        dispatch(familyRelationReducer({ path: 'familyRelation' }));
        dispatch(getStateReducer());
    }, [dispatch])

    const fetchProfileData = async () => {
        try {
            setLoading(true)
            const result = await getProfileApi();
            if (result?.success) {
                setLoading(false)
                const profileData = result?.patientProfileDetails;
                if (profileData) {
                    setFormState((prev) => ({
                        ...prev,
                        fullname: profileData.fullname ? profileData.fullname : '',
                        email: profileData.email ? profileData.email : '',
                        mobile: profileData.phone ? profileData.phone : null,
                        dateofbirth: profileData.dateofbirth ? profileData.dateofbirth : '',
                        age: profileData.age ? profileData.age : '',
                        isProfileCompleted: profileData.isProfileCompleted ? profileData.isProfileCompleted : 0,
                        gender: profileData.gender ? profileData.gender : '',
                        height: profileData.height ? profileData.height : '',
                        weight: profileData.weight ? profileData.weight : '',
                        bloodgroup: profileData.bloodgroup ? profileData.bloodgroup : '',
                        emergency_contact: profileData.emergency_contact ? profileData.emergency_contact : '',
                        city: profileData.city ? profileData.city : '',
                        state: profileData.state ? profileData.state : '',
                        zip_code: profileData.zip_code ? profileData.zip_code : '',
                        profile_image: profileData.profile_image ? profileData.profile_image : '',
                        abha_id: profileData.abha_id ? profileData.abha_id : '',
                        medications: profileData.medications ? profileData.medications : [],
                        medical_allergies: profileData.medical_allergies ? profileData.medical_allergies : [],
                        health_problems: profileData.health_problems ? profileData.health_problems : [],
                        past_operation_detail: profileData.past_operation_detail ? profileData.past_operation_detail : '',
                    }));
                    localStorage.setItem('isProfileCompleted', JSON.stringify(profileData?.isProfileCompleted === 1 ? true : false))
                    dispatch(setIsProfileComplete(profileData?.isProfileCompleted === 1 ? true : false))
                }
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

    useEffect(() => {
        fetchProfileData();
    }, []);

    const handleAutoComplete = (name: keyof FormState, value: any, index?: number) => {
            setFormState((prev) => ({ ...prev, [name]: value }));
    };

    const handleInputChange: HandleInputChangeType = (e) => {
        const { name, value } = e.target;

            setFormState((prev: FormState) => ({ ...prev, [name as keyof FormState]: value }));
    };
    const handleSelectChange: HandleSelectChangeType = (e) => {
        const { name, value } = e.target;
            setFormState((prev: FormState) => ({ ...prev, [name as keyof FormState]: value }));
    };

    const handleDateChange: HandleDateChangeType = (date, name) => {
        const formattedDate = moment(date).format('DD-MM-YYYY');
        const nestedName = name?.split(".");
        if (nestedName?.length > 1) {
            setFormState((prev: FormState) => {
                const [first, second] = nestedName;
                return {
                    ...prev,
                    [first as keyof FormState]: {
                        ...(prev[first as keyof FormState] as object),
                        [second]: formattedDate,
                    },
                };
            });
        } else {
            setFormState((prev: FormState) => ({ ...prev, [name as keyof FormState]: formattedDate }));
        }
        if (name === 'dateofbirth') {
            const age = calculateAge(formattedDate)
            setFormState((prev) => ({ ...prev, age: age?.toString() }))
        }
    };

    const handleFileChange = (name: string, file: File | null, index?: number) => {
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64data = reader.result;
                if (typeof base64data === 'string') {
                        setFormState((prev) => ({ ...prev, profile_image: base64data, imgChange: true }));
                }
            };

            reader.onerror = () => {
                console.error('There was an error reading the file!');
            };

            reader.readAsDataURL(file);
        }
    };

    const RemoveProfileImg = (index?: number) => {
            setFormState((prev) => ({ ...prev, profile_image: '' }))
    }

    const fetchApiUrl = async (Url: string) => {
        try {
            const result = await uploadFileApi({ file: Url, type: 'patient' })
            if (result?.success) {
                return result?.fileUrl
            }
        } catch (error: any) {
            toast.error(error?.data?.errors || error?.message);
        }
    }


    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault()
            const PtImg = (formState?.imgChange && formState?.profile_image) && await fetchApiUrl(formState?.profile_image);
            const sendData = {
                fullname: formState?.fullname,
                email: formState?.email,
                phone: formState?.mobile,
                dateofbirth: formState?.dateofbirth,
                gender: formState?.gender,
                city: formState?.city,
                state: formState?.state,
                zip_code: formState?.zip_code,
                age: formState?.age,
                height: formState?.height,
                weight: formState?.weight,
                bloodgroup: formState?.bloodgroup,
                emergency_contact: formState?.emergency_contact,
                profile_image: PtImg?.fileUrl ? PtImg?.fileUrl : formState?.profile_image,
                abha_id: formState?.abha_id,
            };
            setBtnLoading(true);
            const result = await updateProfileApi(sendData);
            if (result?.success) {
                setBtnLoading(false)
                fetchProfileData()
                toast.success(result?.message)
            }
            else {
                setBtnLoading(false)
                toast.error(result?.message)
            }
        } catch (error: any) {
            setBtnLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

    if (loading) {
        return <Loader />
    }

    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <form noValidate={false} onSubmit={handleSubmit}>
                    <div className={'HeadAndButton'}>
                        <h2>Patient Profile</h2>
                        <button className='fillButton' disabled={btnLoading}> {btnLoading && <BtnLoader />} Save profile</button>
                    </div>
                    <div className="grid grid-cols-4 gap-4">
                        <section className={styles.ProfileImageSec}>
                            <div className={styles.profileImg}>
                                <img src={formState?.profile_image ? formState?.profile_image : avtarIcon} alt='Patient Image' />
                            </div>
                            <div className={styles.chooseProfileAndRemove}>
                                <InputFile
                                    title="Choose profile"
                                    colorBorder={true}
                                    id={"profile_image"}
                                    name={"profile_image"}
                                    onlyImg={true}
                                    handleFileChange={handleFileChange}
                                />
                                <h4 onClick={() => RemoveProfileImg()}>Remove</h4>
                            </div>
                        </section>
                        <section className={`col-span-3 ${styles.ProfileDetailsSec}`}>
                            <div className="grid grid-cols-3 gap-y-6 gap-x-16">
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Name <span className='errorMsg'>*</span></FormLabel>
                                    <TextField
                                        type='text'
                                        className={`custumTextField profileInput`}
                                        name="fullname"
                                        required
                                        placeholder='Enter your name'
                                        value={formState.fullname ?? ''}
                                        onChange={handleInputChange}
                                        onKeyPress={(event) => {
                                            if (!/[a-zA-Z\s]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Email Address <span className='errorMsg'>*</span></FormLabel>
                                    <TextField
                                        type='email'
                                        className={`custumTextField profileInput`}
                                        name="email"
                                        required
                                        placeholder='Enter your email'
                                        disabled
                                        value={formState.email}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Reg. Number<span className='errorMsg'>*</span></FormLabel>
                                    <TextField
                                        type="text"
                                        className={`custumTextField profileInput`}
                                        name="regNumber"
                                        required
                                        placeholder='Enter your mobile'
                                        value={formState.mobile}
                                        inputProps={{
                                            inputMode: 'numeric',
                                            maxLength: 10,
                                        }}
                                        onKeyPress={handleKeyPress}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Contact Number <span className='errorMsg'>*</span></FormLabel>
                                    <TextField
                                        type="text"
                                        className={`custumTextField profileInput`}
                                        name="mobile"
                                        required
                                        placeholder='Enter your mobile'
                                        value={formState.mobile}
                                        inputProps={{
                                            inputMode: 'numeric',
                                            maxLength: 10,
                                        }}
                                        onKeyPress={handleKeyPress}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Date of Birth <span className='errorMsg'>*</span></FormLabel>
                                    <DatePicker
                                        className="custumTextField profileInput"
                                        value={moment(formState.dateofbirth, 'DD-MM-YYYY')}
                                        maxDate={moment(new Date(), 'DD-MM-YYYY')}
                                        format="DD-MM-YYYY"
                                        disabled={formState?.isProfileCompleted === 1 ? true : false}
                                        onChange={(date) => handleDateChange(moment(date)?.toDate(), 'dateofbirth')}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Gender<span className='errorMsg'>*</span></FormLabel>
                                    <Select
                                        className={`custumTextField profileInput`}
                                        placeholder="Select gender"
                                        value={formState.gender}
                                        name='gender'
                                        disabled={formState?.isProfileCompleted === 1 ? true : false}
                                        onChange={handleSelectChange}
                                    >
                                        <MenuItem value="Male">Male</MenuItem>
                                        <MenuItem value="Female">Female</MenuItem>
                                        <MenuItem value="Others">Others</MenuItem>
                                    </Select>
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Address <span className='errorMsg'>*</span></FormLabel>
                                    <TextField
                                        type="text"
                                        className={`custumTextField profileInput`}
                                        name="zip_code"
                                        placeholder='Enter your address'
                                        required
                                        value={formState?.zip_code}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">State <span className='errorMsg'>*</span></FormLabel>
                                    <Select
                                        className={`custumTextField profileInput`}
                                        placeholder="Select state"
                                        value={formState?.state}
                                        name='state'
                                        onChange={handleSelectChange}
                                    >
                                        {
                                            stateData?.length > 0 && stateData?.map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item?.state}>{item?.state}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">City <span className='errorMsg'>*</span></FormLabel>
                                    <Select
                                        className={`custumTextField profileInput`}
                                        placeholder="Select city"
                                        value={formState?.city}
                                        name='city'
                                        disabled={formState?.state !== "" ? false : true}
                                        onChange={handleSelectChange}
                                    >
                                        {
                                            cityData?.length > 0 && cityData?.map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item?.city}>{item?.city}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Height</FormLabel>
                                    <TextField
                                        type="text"
                                        className={`custumTextField profileInput`}
                                        name="height"
                                        placeholder='Enter height'
                                        value={formState.height}
                                        InputProps={{
                                            inputMode: 'numeric',
                                            endAdornment: <InputAdornment position="end">in cm</InputAdornment>,
                                        }}
                                        onKeyPress={handleKeyPress}
                                        onChange={handleInputChange}

                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Weight</FormLabel>
                                    <TextField
                                        type="text"
                                        className={`custumTextField profileInput`}
                                        name="weight"
                                        placeholder='Enter weight'
                                        value={formState.weight}
                                        InputProps={{
                                            inputMode: 'numeric',
                                            endAdornment: <InputAdornment position="end">in kg</InputAdornment>,
                                        }}
                                        onChange={handleInputChange}
                                        onKeyPress={handleKeyPress}
                                    />
                                </FormGroup>
                            </div>
                        </section>
                    </div>
                </form>
            </div>
            <GenerateAbhaId />
        </CustomContainer>
    );
};

export default Profile;