import React, { ChangeEvent, useEffect, useState } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import styles from "../../assets/styles/chat.module.css"
import UseSocket from '../../utils/functions/useSocket'
import { getChatHistoryApi, getChatUserListApi, updateChatStatusApi } from '../../utils/api/services'
import { useSelector } from 'react-redux'
import { RootState } from '../../utils/store'
import { toast } from 'react-toastify'
import ChatSidebar from '../../components/chat/ChatSidebar'
import ChatBody from '../../components/chat/ChatBody'

const Chat = () => {
  const [messages, setMessages] = useState<ChatHistoryItem[] | []>([]);
  const [newMessage, setNewMessage] = useState<string>('');
  const [userList, setUserList] = useState<ChatPartner[] | []>([])
  const { LoginData } = useSelector((state: RootState) => state.auth)
  const [particularUser, setParticularUser] = useState<ChatPartner | null>(null)
  const [searchValue, setSearchValue] = useState('');
  const { isConnected, connect, disconnect, sendMessage, listenForEvent } = UseSocket();

  // chat functionality start
  useEffect(() => {
    connect();
    return () => {
      disconnect();
    };
  }, [connect, disconnect]);


  const IntiatChat = async () => {
    try {
      if (LoginData?.loggedPatientId) {
        const result: any = await sendMessage('listen', { userId: LoginData?.loggedPatientId, userType: 1 });
      }

    } catch (error) {

    }
  }

  useEffect(() => {
    listenForEvent(`chatList`, (data: ChatListSocket) => {
      if (data?.senderId === LoginData?.loggedPatientId && data?.senderType === 1) {
        setUserList(data?.senderChatList);
      }
      if (data?.receiverId === LoginData?.loggedPatientId && data?.receiverType === 1) {
        setUserList(data?.receiverChatList);
      }
    });
    listenForEvent(`chatHistory`, (data: ChatHistorySocket) => {
      if (data?.senderId === LoginData?.loggedPatientId && data?.senderType === 1 && data?.receiverId === particularUser?.chatPartnerId) {
        setMessages(data?.senderChatHistory);
      }
      if (data?.receiverId === LoginData?.loggedPatientId && data?.receiverType === 1 && data?.senderId === particularUser?.chatPartnerId) {
        setMessages(data?.receiverChatHistory);
      }
    });
    IntiatChat();
  }, [listenForEvent])


  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      const sendData = {
        senderId: LoginData?.loggedPatientId,
        senderType: 1,
        receiverId: particularUser?.chatPartnerId,
        receiverType: 3,
        message: newMessage,
        messageType: 1,
      }
      try {
        const result: any = await sendMessage('sendMessage', sendData);
        if (result?.success && LoginData?.loggedPatientId) {
          // fetchChatHistory({ senderId: LoginData?.id, receiverId: particularUser?.chatPartnerId ? particularUser?.chatPartnerId : 0, senderType: 3, receiverType: 1 })
        }
      } catch (error: any) {
        console.error(error.message);
      }
      setNewMessage('');
    }
  };

  // chat functionality end

  const fetchUpdateReadStatus = async (data: {
    senderId: number;
    receiverId: number;
    senderType: number;
    receiverType: number;
  }) => {
    try {
      const result = await updateChatStatusApi(data);
      if (result?.success) {
        if (LoginData?.loggedPatientId) {
          fetchUserList({ userId: LoginData?.loggedPatientId, userType: 1, search: searchValue ? searchValue : undefined });
        }

      }
    } catch (error) {

    }
  }
  const fetchChatHistory = async (data: { senderId: number, receiverId: number, senderType: number, receiverType: number }) => {
    try {
      const result = await getChatHistoryApi(data);
      if (result?.success) {
        setMessages(result?.chatHistory)
      }
      else {
        setMessages(result?.chatHistory)
      }
    } catch (error: any) {
      toast.error(error?.data?.errors || error?.message);
    }
  }


  const fetchUserList = async (data: { userId: number, userType: number, search: string | undefined }) => {
    try {
      const result = await getChatUserListApi(data);
      if (result?.success) {
        setUserList(result?.chatList);
      }
    } catch (error: any) {
      toast.error(error?.data?.errors || error?.message);
    }
  }
  useEffect(() => {
    if (LoginData?.loggedPatientId) {
      if (searchValue) {
        const ClearFunction = setTimeout(() => {
          fetchUserList({ userId: LoginData?.loggedPatientId, userType: 1, search: searchValue ? searchValue : undefined });
        }, 1000);
        return () => clearTimeout(ClearFunction)
      }
      else {
        fetchUserList({ userId: LoginData?.loggedPatientId, userType: 1, search: undefined });
      }
    }
  }, [searchValue])

  const handleParticularUser = async (data: ChatPartner) => {
    setParticularUser(data)
    setNewMessage('')
    if (LoginData?.loggedPatientId) {
      if (data?.unreadMessages !== '0') {
        fetchUpdateReadStatus({ senderId: LoginData?.loggedPatientId, receiverId: data?.chatPartnerId, senderType: 1, receiverType: 3 })
      }
      fetchChatHistory({ senderId: LoginData?.loggedPatientId, receiverId: data?.chatPartnerId, senderType: 1, receiverType: 3 });
      // await sendMessage('createRoom', {senderId: LoginData?.loggedPatientId, receiverId: data?.chatPartnerId, senderType: 1, receiverType: 3});
    }
  }
  const handleChangeMsg = (e: ChangeEvent<HTMLInputElement>) => {
    setNewMessage(e.target.value)
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const file = e.target.files?.[0] ?? null;
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Data = reader.result as string;

      };
      reader.onerror = (error) => console.error("Error reading file:", error);
    }
  }

  return (
    <CustomContainer>
      <div className='AllPageMain'>
        <div className='HeadAndButton'>
          <h2>Inbox</h2>
        </div>
        <div className={styles.main}>
          <div className='grid grid-cols-4 gap-6'>
            <div className='col-span-1'>
              <ChatSidebar
                setSearchValue={setSearchValue}
                handleParticularUser={handleParticularUser}
                userList={userList}
                searchValue={searchValue}
                particularUser={particularUser}
              />
            </div>
            <div className={`col-span-3`}>
              <ChatBody
                handleFileChange={handleFileChange}
                handleSendMessage={handleSendMessage}
                newMessage={newMessage}
                handleChangeMsg={handleChangeMsg}
                messages={messages}
                particularUser={particularUser}
              />
            </div>
          </div>
        </div>
      </div>
    </CustomContainer>
  )
}

export default Chat