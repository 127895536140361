import { Box, Modal } from '@mui/material'
import React from 'react'
import { CrossIcon } from '../../utils/functions/Icons'
import BtnLoader from './BtnLoader'
interface propTypes {
    openConfirmModal: boolean,
    CloseConfirmModal: () => void,
    confirmSubmit: () => void,
    pendingCall: () => void,
    btnLoading?: boolean,
    title: string;
    DoNotCloseNotification?: boolean;
}
const CallDisconnectMadal: React.FC<propTypes> = ({
    openConfirmModal,
    CloseConfirmModal,
    confirmSubmit,
    btnLoading,
    title,
    DoNotCloseNotification,
    pendingCall
}) => {
    return (
        <Modal
            open={openConfirmModal}
            onClose={CloseConfirmModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ width: 400 }} className={`ModalBoxStyle ${DoNotCloseNotification ? 'DoNotCloseNotification' : ''}`}>
                <div className="modalHeader">
                    <h3>{title}</h3>
                    <CrossIcon onClick={CloseConfirmModal} />
                </div>
                <div className='ModalContentSection'>
                    <div className='confirmationBtns'>
                        <button className='outLineBtn' onClick={pendingCall}>Pending</button>
                        <button className='fillButton' disabled={btnLoading} onClick={confirmSubmit}>{btnLoading && <BtnLoader />} Completed</button>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default CallDisconnectMadal