import React from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import IncomingCallList from './IncomingCallList'
import styles from "../../assets/styles/dashboard.module.css"
const Dashboard = () => {
    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <div className={'HeadAndButton'}>
                    <h2>Dashboard</h2>
                </div>
                <div className={styles.main}>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                        <IncomingCallList />
                    </div>
                </div>
            </div>
        </CustomContainer>
    )
}

export default Dashboard