import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Login from './pages/Login'
import ScrollToTop from './components/common/ScrollToTop'
import Layout from './components/layout'
import ForgotPassword from './pages/ForgotPassword'
import LoginOtp from './pages/LoginOtp'
import ChangePassword from './pages/ChangePassword'
import Dashboard from './pages/AuthPages/Dashboard'
import Profile from './pages/AuthPages/Profile'
import MyAppointment from './pages/AuthPages/MyAppointment'
import SavedDocument from './pages/AuthPages/SavedDocument'
import SavedDocumentView from './pages/AuthPages/SavedDocumentView'
import BookAppointmentThanks from './pages/AuthPages/BookAppointmentThanks'
import TalkToDoctor from './pages/AuthPages/TalkToDoctor'
import Help from './pages/AuthPages/Help'
import AppointmentDetails from './pages/AuthPages/AppointmentDetails'
import ProtectedRoute from './components/common/ProtectedRoute'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from './utils/store'
import CallNow from './pages/AuthPages/CallNow'
import Chat from './pages/AuthPages/Chat'
import Setting from './pages/AuthPages/Setting'
import Availability from './pages/AuthPages/Availability'
import MyPatients from './pages/AuthPages/MyPatients'
import useSocket from './utils/functions/useSocket'
import GlobalCallNotification from './components/common/GlobalCallNotification'

const App = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  const [incomingCalls, setIncomingCalls] = useState<any[]>([]);
  const { connect, listenForEvent, disconnect, sendMessage } = useSocket();
  const { LoginData } = useSelector((state: RootState) => state.auth)
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    connect();
    return () => {
      disconnect();
    };
  }, [connect, disconnect]);

  React.useEffect(() => {
    listenForEvent(`incomingCallListFront`, (data: any) => {
      setIncomingCalls((prevCalls) => {
        const isDuplicate = prevCalls.some(call =>
          call.waitingList[0]?.call_id === data.waitingList[0]?.call_id
        );
        if (!isDuplicate) {
          const filteredWaitingList = data.waitingList.filter((waitingListItem: WaitingListItem) =>
            waitingListItem.pcp_doctor_id === LoginData?.connectDocId &&
            waitingListItem.group_id === LoginData?.group_id
          );
          if (filteredWaitingList.length > 0) {
            data.waitingList = filteredWaitingList;
            return [...prevCalls, data];
          }
        }
        return prevCalls;
      });
    });
  }, [listenForEvent]);

  return (
    <>
      <GlobalCallNotification
        incomingCalls={incomingCalls}
        setIncomingCalls={setIncomingCalls}
      />
      <ScrollToTop />
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/otp' element={<LoginOtp />} />
        <Route path='/change-password' element={<ChangePassword />} />
        <Route path="/*"
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }>
          <Route path='myaccount/profile' element={<Profile />} />
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='appointments' element={<MyAppointment />} />
          <Route path='MyAppointments/book/thanks' element={<BookAppointmentThanks />} />
          <Route path='SavedDocuments' element={<SavedDocument />} />
          <Route path='SavedDocuments/view' element={<SavedDocumentView />} />
          <Route path='help' element={<Help />} />
          <Route path='availability' element={<Availability />} />
          <Route path='MyAppointments/details' element={<AppointmentDetails />} />
          <Route path='myaccount/settings' element={<Setting />} />
          <Route path='TalkToDoctor' element={<TalkToDoctor />} />
          <Route path='CallNow' element={<CallNow />} />
          <Route path='patient' element={<MyPatients />} />
          <Route path='inbox' element={<Chat />} />
          <Route path='*' element={<Dashboard />} />
          <Route
            path="/*"
            element={<Navigate to={"/dashboard"} replace />}
          />
        </Route>
        <Route
          path="/"
          element={<Navigate to={!token ? "/login" : '/patient'} replace />}
        />
      </Routes>
    </>
  )
}

export default App