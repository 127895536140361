import yogaImg from "../../assets/images/common/yoga.png";
import avtarIcon from "../../assets/images/common/avtar.png";
import filesIcon from "../../assets/images/common/fileicon.png";
import pdfIcon from "../../assets/images/common/pdficon.png";
import userImg from "../../assets/images/common/usericon.png";
import QrCodeImg from "../../assets/images/common/qrcode.png";
import noDataImg from "../../assets/images/common/no-appointments.png";
import logo from "../../assets/images/layout/sidebarlogo.png";
export const Images = {
  yogaImg,
  avtarIcon,
  filesIcon,
  noDataImg,
  logo,
  userImg,
  QrCodeImg,
  pdfIcon
};
