import { Box, Modal, Typography, Button } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from "../../assets/styles/videocall.module.css"
import { Images } from '../../utils/functions/Images';
import { AcceptIcon, DeclineIcon } from '../../utils/functions/Icons';
import { updateDoctorIdApi, updateStatusByCallIdApi } from '../../utils/api/services';
import { toast } from 'react-toastify';
import useSocket from '../../utils/functions/useSocket';
import { useSelector } from 'react-redux';
import { RootState } from '../../utils/store';

interface IncomingCall {
    waitingList: WaitingListItem[];
}

interface GlobalCallNotificationProps {
    incomingCalls: IncomingCall[];
    setIncomingCalls: React.Dispatch<React.SetStateAction<IncomingCall[]>>;
}

const GlobalCallNotification: React.FC<GlobalCallNotificationProps> = ({ incomingCalls, setIncomingCalls }) => {
    const [open, setOpen] = React.useState(false);
    const [waitingList, setWaitingList] = useState<WaitingListItem | null>(null)
    const navigate = useNavigate()
    const { sendMessage } = useSocket();
    const { LoginData } = useSelector((state: RootState) => state.auth)

    React.useEffect(() => {
        if (incomingCalls.length > 0) {
            setWaitingList(incomingCalls[0]?.waitingList[0])
            setOpen(true);
        }
    }, [incomingCalls]);

    const handleAcceptCall = async (waitingList: WaitingListItem) => {
        if (waitingList) {
            try {
                const sendData = {
                    "callid": waitingList?.call_id,
                    "docid": waitingList?.pcp_doctor_id,
                    "groupid": waitingList?.group_id,
                    "patientid": waitingList?.patient_id
                }
                const result = await updateDoctorIdApi(sendData);
                if (result?.success) {
                    setIncomingCalls((prev) => prev.slice(1));
                    setOpen(false);
                    navigate('/callnow', {
                        state: {
                            waitingList,
                        }
                    })
                } else { toast.error(result?.message) }
            } catch (error: any) {
                toast.error(error?.data?.status_message || error?.message);
            }
        }
    };

    const fetchIncomingCallSocket = async () => {
        try {
            const result = await sendMessage('incomingCall', { docid: LoginData?.connectDocId, groupId: LoginData?.group_id })
            if (result?.success) {
                const data = result?.data;
                setIncomingCalls((prevCalls) => {
                    const isDuplicate = prevCalls.some(call =>
                        call.waitingList[0]?.call_id === data.waitingList[0]?.call_id
                    );
                    if (!isDuplicate) {
                        const filteredWaitingList = data.waitingList.filter((waitingListItem: WaitingListItem) =>
                            waitingListItem.pcp_doctor_id === LoginData?.connectDocId &&
                            waitingListItem.group_id === LoginData?.group_id
                        );
                        if (filteredWaitingList.length > 0) {
                            data.waitingList = filteredWaitingList;
                            return [...prevCalls, data];
                        }
                    }
                    return prevCalls;
                });
            }
        } catch (error) {
        }
    }
    const handleRejectCall = async () => {
        try {
            const sendData = {
                call_id: waitingList?.call_id,
                status: "DELETED",
            }
            const result = await updateStatusByCallIdApi(sendData);
            if (result?.success) {
                // fetchIncomingCallSocket();
                setIncomingCalls((prev) => prev.slice(1));
                setOpen(false);
            }
            else {
                toast.error(result?.message)
            }
        } catch (error: any) {
            toast.error(error?.data?.status_message || error?.message);
        }
    };

    return (
        <Modal
            open={open}
            aria-labelledby="incoming-call-modal"
            aria-describedby="incoming-call-description"
        >
            <Box sx={{ width: 500 }} className={"ModalBoxStyle"}>
                {
                    waitingList ? (
                        <div className={styles.CallNotiMain}>
                            <div className={styles.imgSec}>
                                <img src={waitingList?.profile_image ? waitingList?.profile_image : Images.avtarIcon} alt="User Icon" />
                            </div>
                            <div className={styles.userDetailsSec}>
                                <div className={styles.UNameSec}>
                                    <div className={styles.UName}>
                                        <h3>{waitingList?.name}</h3>
                                        <p>Patient ID: {waitingList?.uniquePatientId}</p>
                                    </div>
                                    <div className={styles.GName}>
                                        <h3>Gender</h3>
                                        <p>{waitingList?.gender}</p>
                                    </div>
                                    <div className={styles.GName}>
                                        <h3>Age</h3>
                                        <p>{waitingList?.age}</p>
                                    </div>
                                </div>
                                <div className={styles.UNameSec}>
                                    <div className={styles.LName}>
                                        <h3>Location</h3>
                                        <p>{`${waitingList?.city}, ${waitingList?.state}`}</p>
                                    </div>
                                    {
                                        waitingList?.approvedByCareNavigator &&
                                        <div className={styles.LName}>
                                            <h3>Care Navigator</h3>
                                            <p>{waitingList?.approvedByCareNavigator}</p>
                                        </div>
                                    }
                                </div>
                                <div className={styles.AdBtnMain}>
                                    <button className='fillButton' onClick={() => handleAcceptCall(waitingList)}> <AcceptIcon /> Accept</button>
                                    <button className='fillButton DeclineBtn' onClick={handleRejectCall}><DeclineIcon /> Decline</button>
                                </div>
                            </div>
                        </div>
                    )
                        :
                        <Typography>No incoming call.</Typography>
                }

            </Box>
        </Modal>
    );
};

export default GlobalCallNotification;
