import CustomContainer from "../../components/common/CustomContainer"
import styles from "../../assets/styles/appointments.module.css"
import MyPatientTable from "../../components/myPatient/MyPatientTable"

const MyPatients = () => {
    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <div className={'HeadAndButton'}>
                    <h2>My Patients</h2>
                </div>
                <div className={'mt-8'}>
                    <MyPatientTable />
                </div>
            </div>
        </CustomContainer>
    )
}

export default MyPatients