import React, { FC, useEffect, useRef, useState } from 'react'
import PatientDataCard from '../common/PatientDataCard';
import CustomContainer from '../common/CustomContainer';
import { Chat2Icon, ChatFileIcon, ChatIcon, ChatSendIcon, EndCallIcon, FullScreenIcon, InFoIcon, Mic2Icon, MicIcon, ScreenshareIcon, SwitchCameraIcon, Video2Icon, VideoIcon } from '../../utils/functions/Icons';
import { Images } from '../../utils/functions/Images';
import { FormGroup, InputAdornment, TextField } from '@mui/material';
import OT from "@opentok/client";
import { callEndByPatientApi, disconnectReasonApi, getGenerateCallIdByPatientApi, getTalkToDoctorApi, updateStatusByCallIdApi } from '../../utils/api/services';
import { toast } from 'react-toastify';
import ConfirmModal from '../common/ConfirmModal';
import { useNavigate } from 'react-router-dom';
import CallDisconnectMadal from '../common/CallDisconnectModal';
import VideoCallSidebar from './VideoCallSidebar';

interface Props {
  apiKey: string;
  sessionId: string;
  token: string;
  TalkToDoctorData: WaitingListItem | null;
  callid: number | null
}
interface CameraDevice {
  deviceId: string;
}
const VideoCall: FC<Props> = ({ apiKey, sessionId, token, TalkToDoctorData, callid }) => {
  const [showChat, setShowChat] = useState(false);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [isVideoOff, setIsVideoOff] = useState<boolean>(false);
  const [isSwitchCamera, setIsSwitchCamera] = useState<boolean>(false);
  const [connected, setConnected] = useState(false)
  const [isSharingScreen, setIsSharingScreen] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [chatMessages, setChatMessages] = useState<{ text: string; className: string, isPtient: boolean }[]>([]);
  const [reasonDisconnect, setReasonDisconnect] = useState("")
  // switch camera
  const [cameras, setCameras] = useState<CameraDevice[]>([]);
  const [currentCameraIndex, setCurrentCameraIndex] = useState<number>(0);
  // switch camera

  const sessionRef = useRef<OT.Session | null>(null);
  const publisherRef = useRef<OT.Publisher | null>(null);
  const screenPublisherRef = useRef<OT.Publisher | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = 'Are you sure you want to end your call?';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleError = (error: any) => {
    if (error) {
      alert(error.message);
    }
  };

  const initializeSession = () => {
    if (sessionRef.current) {
      return;
    }

    const session = OT.initSession(apiKey, sessionId);
    sessionRef.current = session;

    const onStreamCreated = (event: any) => {
      session.subscribe(
        event.stream,
        "subscriber",
        {
          insertMode: "append",
          width: "100%",
          height: "100%",
        },
        handleError
      );
    };
    session.on("streamCreated", onStreamCreated);


    if (!publisherRef.current) {
      const publisher = OT.initPublisher(
        "publisher",
        {
          insertMode: 'append',
          width: '100%',
          height: '100%',
        },
        handleError
      );
      publisherRef.current = publisher;
    }

    session.connect(token, (error) => {
      if (error) {
        handleError(error);
      } else {
        session.publish(publisherRef.current!, handleError);
        setConnected(true);
      }
    });

    return () => {
      if (sessionRef.current) {
        sessionRef.current.off("streamCreated", onStreamCreated);
        sessionRef.current = null;
      }
    };
  };

  useEffect(() => {
    const cleanup = initializeSession();
    return cleanup;
  }, [apiKey, sessionId, token]);

  const toggleMute = () => {
    const publisher = publisherRef.current;
    if (publisher) {
      const shouldMute = !isMuted;
      publisher.publishAudio(!shouldMute);
      setIsMuted(shouldMute);
    }
  };

  const handleShowChat = () => {
    setShowChat(!showChat);
  };

  const toggleVideo = () => {
    const publisher = publisherRef.current;
    if (publisher) {
      const shouldTurnVideoOff = !isVideoOff;
      publisher.publishVideo(!shouldTurnVideoOff);
      setIsVideoOff(shouldTurnVideoOff);
    }
  };

  const fetchUpdateStatusByCallId = async (data: any) => {
    try {

      const result = await updateStatusByCallIdApi(data);
      if (result?.success) {
        setOpenConfirmModal(false);
        navigate(-1)
      }
    } catch (error: any) {
      toast.error(error?.data?.status_message || error?.message);
    }
  }
  const pendingCall = () => {
    if (sessionRef.current) {
      sessionRef.current.disconnect();
      publisherRef.current = null;
      const sendData = {
        "call_id": callid,
        "status": "PENDING",
        "callEnd": 1,
        "reason": ""
      }
      fetchUpdateStatusByCallId(sendData)
    }
  }

  const endCall = () => {
    if (sessionRef.current) {
      sessionRef.current.disconnect();
      publisherRef.current = null;
      const sendData = {
        "call_id": callid,
        "status": "COMPLETED",
        "callEnd": 1,
        "reason": ""
      }
      fetchUpdateStatusByCallId(sendData)
    }
  };

  const CloseConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  const handleEndCall = () => {
    setOpenConfirmModal(true);
  };

  const toggleScreenShare = () => {
    const session = sessionRef.current;
    if (session) {
      if (isSharingScreen) {
        if (screenPublisherRef.current) {
          session.unpublish(screenPublisherRef.current);
          screenPublisherRef.current.destroy();
          screenPublisherRef.current = null;
        }
      } else {
        const screenPublisher = OT.initPublisher(
          "publisher",
          {
            videoSource: "screen",
            insertMode: "append",
            width: "100%",
            height: "100%",
          },
          handleError
        );
        screenPublisherRef.current = screenPublisher;
        session.publish(screenPublisher, handleError);
      }
      setIsSharingScreen(!isSharingScreen);
    }
  };

  const toggleFullScreen = () => {
    const elem = document.documentElement;
    if (!document.fullscreenElement) {
      elem.requestFullscreen().catch((err) => {
        alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    } else {
      document.exitFullscreen();
    }
  };

  // Switch camera functionality
  const listCameras = () => {
    OT.getDevices((error, devices) => {
      if (error) {
        console.error('Error listing media devices:', error);
        return;
      }
      const videoDevices: CameraDevice[] = devices
        ?.filter(device => device?.kind === "videoInput")
        .map(device => ({ deviceId: device?.deviceId })) || [];
      setCameras(videoDevices);
    });
  };

  useEffect(() => {
    if (connected) {
      listCameras();
    }
  }, [connected]);

  const switchCamera = async (deviceId: string) => {
    if (!sessionRef?.current || !publisherRef?.current) return;

    sessionRef?.current?.unpublish(publisherRef.current);
    publisherRef?.current?.destroy();
    const newPublisher = OT.initPublisher(
      'publisher',
      {
        videoSource: deviceId,
        insertMode: 'append',
        width: '100%',
        height: '100%',
      },
      handleError
    );

    publisherRef.current = newPublisher;
    sessionRef.current?.publish(newPublisher, handleError);
    setIsSwitchCamera(!isSwitchCamera);
  };

  const cycleCamera = () => {
    const nextIndex = (currentCameraIndex + 1) % cameras.length;
    setCurrentCameraIndex(nextIndex);
    switchCamera(cameras[nextIndex].deviceId);
  };
  // switch camera end

  // chat start
  const sendMessage = (event: React.FormEvent) => {
    event.preventDefault();
    try {

    } catch (error) {

    }
  };

  return (
    <CustomContainer>
      <div className={'AllPageMain'}>
        <div className='headerMain'>
          {
            TalkToDoctorData &&
            <PatientDataCard
              profilePic={TalkToDoctorData?.profile_image}
              name={TalkToDoctorData?.name}
              patientId={TalkToDoctorData?.patient_id}
              age={TalkToDoctorData?.age}
              dateofbirth={TalkToDoctorData?.dateofbirth}
              gender={TalkToDoctorData?.gender}
              location={`${TalkToDoctorData?.state}, ${TalkToDoctorData?.city}`}
            />
          }
        </div>
        <div className='grid grid-cols-5 gap-6'>
          <div className='VideoCallMain col-span-3'>
            <div className={`grid ${showChat ? 'grid-cols-7' : 'grid-cols-1'} gap-4`}>
              <section className={`${showChat ? 'col-span-5' : ''} videoSection`}>
                <div className='VideoSectionMain'>
                  {/* <div className='doctorData'>
                                    <p>Dr. Basu</p>
                                    <p>Registration No - 8749732947394</p>
                                    <p>Specialising in - Cardiac care</p>
                                </div> */}
                  <div className='fullScreenModeIcon' onClick={toggleFullScreen}>
                    <FullScreenIcon />
                  </div>
                  <div className='videoScreens'>
                    <div id="subscriber" className={`SubscriberConnected`}></div>
                    <div id="publisher" className='PublisherConnected'></div>
                  </div>
                </div>
                <div className={`controlesBtns`}>
                  <div className={`roundedBtns ${isMuted ? 'active' : ''}`} onClick={toggleMute}>
                    {
                      isMuted ? <Mic2Icon /> : <MicIcon />
                    }

                  </div>
                  <div className={`roundedBtns ${isVideoOff ? 'active' : ''}`} onClick={toggleVideo}>
                    {
                      isVideoOff ? <Video2Icon /> : <VideoIcon />
                    }
                  </div>
                  <div title='Switch Camera' className={`roundedBtns ${isSwitchCamera ? 'active' : ''}`} onClick={cycleCamera}>
                    <SwitchCameraIcon />
                  </div>
                  <div className={`roundedBtns ${showChat ? 'active' : ''}`} onClick={handleShowChat}>
                    {
                      showChat ? <Chat2Icon /> : <ChatIcon />
                    }
                  </div>
                  <div className={`roundedBtns ${isSharingScreen ? 'active' : ''}`} onClick={toggleScreenShare}>
                    <ScreenshareIcon />
                  </div>
                  <div className='callEndBtn' onClick={handleEndCall}>
                    <EndCallIcon />  End Call
                  </div>
                </div>
              </section>
              {showChat && (
                <section className={`chatSection ${showChat ? 'col-span-2' : ''}`}>
                  <div className='chatMainDiv'>
                    <div className='chatHeader'>
                      <div className='chatDocNameImg'>
                        <img src={Images.avtarIcon} alt="doctor image" />
                        <div className='namePro'>
                          <h3>Dr. jhdfwdhf jdqed </h3>
                          <p>Cardiologist</p>
                        </div>
                      </div>
                      <div className='chatInfoIcons'>
                        <InFoIcon />
                      </div>
                    </div>
                    <div className='chatBody'>
                      {chatMessages.map((msg, index) => (
                        <React.Fragment key={index}>
                          {
                            msg?.isPtient ?
                              <div className='patientChat'>
                                <div className='patientMsg'>
                                  <p>
                                    {msg.text}
                                  </p>
                                </div>
                                <div className='patientImg'>
                                  <img src={Images.avtarIcon} alt="Patient Image" />
                                </div>
                              </div>
                              :
                              <div className='doctorChat'>
                                <div className='patientImg'>
                                  <img src={Images.avtarIcon} alt="Patient Image" />
                                </div>
                                <div className='DoctorMsg'>
                                  <p>
                                    {msg.text}
                                  </p>
                                </div>

                              </div>
                          }
                        </React.Fragment>
                      ))}

                    </div>
                    <div className='chatFooter'>

                      <FormGroup className="customFormGroup">
                        <TextField
                          className={`custumTextField custumTextFieldChat`}
                          id="searchValue"
                          name="searchValue"
                          placeholder='Message here'
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <div className='fileIcon'>
                                  <input
                                    type="file"
                                    name={'file_upload'}
                                    id={'file_upload'}
                                    accept={'image/*,.pdf'}
                                    multiple={false}
                                    className="customFileInput"
                                    style={{ display: 'none' }}
                                  />
                                  <label className={`cursor-pointer`} htmlFor={'file_upload'}>
                                    <ChatFileIcon />
                                  </label>
                                </div>
                                <div className='sendMessageBtn' onClick={sendMessage}>
                                  <ChatSendIcon />
                                </div>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormGroup>

                    </div>
                  </div>
                </section>
              )}
            </div>
          </div>
          <div className='col-span-2 h-full'>
            <VideoCallSidebar />
          </div>
        </div>
      </div>
      <CallDisconnectMadal
        title="Call Status?"
        openConfirmModal={openConfirmModal}
        confirmSubmit={endCall}
        pendingCall={pendingCall}
        CloseConfirmModal={CloseConfirmModal}
      />
    </CustomContainer>
  )
}

export default VideoCall;
