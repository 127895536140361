import React, { useEffect, useState } from 'react'
import styles from "../../assets/styles/videocall.module.css"
import { useSelector } from 'react-redux';
import { RootState } from '../../utils/store';
import useSocket from '../../utils/functions/useSocket';
import { Images } from '../../utils/functions/Images';
import { useNavigate } from 'react-router-dom';
import { updateDoctorIdApi, updateStatusByCallIdApi } from '../../utils/api/services';
import { AcceptIcon, DeclineIcon } from '../../utils/functions/Icons';
import { toast } from 'react-toastify';

const IncomingCallList = () => {
    const [incomingCalls, setIncomingCalls] = useState<any[]>([]);
    const { connect, listenForEvent, disconnect, sendMessage } = useSocket();
    const { LoginData } = useSelector((state: RootState) => state.auth)
    const navigate = useNavigate();

    useEffect(() => {
        connect();
        return () => {
            disconnect();
        };
    }, [connect, disconnect]);

    const fetchIncomingCallSocket = async () => {
        try {
            const result = await sendMessage('incomingCall', { docid: LoginData?.connectDocId, groupId: LoginData?.group_id })
            if (result?.success) {
                const data = result?.data;
                if (data?.waitingList?.length === 0) {
                    if (incomingCalls.length !== 0) {  
                        setIncomingCalls([]);
                    }
                    return;
                }
                setIncomingCalls((prevCalls) => {
                    const isDuplicate = prevCalls.some(call =>
                        call.waitingList[0]?.call_id === data.waitingList[0]?.call_id
                    );
                    if (!isDuplicate) {
                        const filteredWaitingList = data.waitingList.filter((waitingListItem: WaitingListItem) =>
                            waitingListItem.pcp_doctor_id === LoginData?.connectDocId &&
                            waitingListItem.group_id === LoginData?.group_id
                        );
                        if (filteredWaitingList.length > 0) {
                            data.waitingList = filteredWaitingList;
                            return [...prevCalls, data];
                        }
                    }
                    return prevCalls;
                });
            }
        } catch (error) {
        }
    }
    React.useEffect(() => {
        fetchIncomingCallSocket();
    }, [sendMessage]);

    const handleAcceptCall = async (waitingList: WaitingListItem) => {
        if (waitingList) {
            try {
                const sendData = {
                    "callid": waitingList?.call_id,
                    "docid": waitingList?.pcp_doctor_id,
                    "groupid": waitingList?.group_id,
                    "patientid": waitingList?.patient_id
                }
                const result = await updateDoctorIdApi(sendData);
                if (result?.success) {
                    setIncomingCalls((prev) => prev.slice(1));
                    navigate('/callnow', {
                        state: {
                            waitingList,
                        }
                    })
                } else { toast.error(result?.message) }
            } catch (error: any) {
                toast.error(error?.data?.status_message || error?.message);

            }
        }
    };
    const handleRejectCall = async (waitingList: WaitingListItem) => {
        setIncomingCalls((prev) => prev.slice(1));
        try {
            const sendData = {
                call_id: waitingList?.call_id,
                status: "DELETED",
            }
            const result = await updateStatusByCallIdApi(sendData);
            if (result?.success) {
                setIncomingCalls((prev) => prev.slice(1));
                fetchIncomingCallSocket();
            }
            else {
                toast.error(result?.message)
            }
        } catch (error: any) {
            toast.error(error?.data?.status_message || error?.message);
        }
    };

    return (
        <div className='grid gap-6'>
            {
                incomingCalls && incomingCalls[0]?.waitingList?.map((item: WaitingListItem, index: number) => {
                    return (
                        <div className={`${styles.CallNotiMain} ${styles.CallNotiBox}`} key={index}>
                            <div className={styles.imgSec}>
                                <img src={item?.profile_image ? item?.profile_image : Images.avtarIcon} alt="User Icon" />
                            </div>
                            <div className={styles.userDetailsSec}>
                                <div className={styles.UNameSec}>
                                    <div className={styles.UName}>
                                        <h3>{item?.name}</h3>
                                        <p>Patient ID: {item?.uniquePatientId}</p>
                                    </div>
                                    <div className={styles.GName}>
                                        <h3>Gender</h3>
                                        <p>{item?.gender}</p>
                                    </div>
                                    <div className={styles.GName}>
                                        <h3>Age</h3>
                                        <p>{item?.age}</p>
                                    </div>
                                </div>
                                <div className={styles.UNameSec}>
                                    <div className={styles.LName}>
                                        <h3>Location</h3>
                                        <p>{`${item?.city}, ${item?.state}`}</p>
                                    </div>
                                    {
                                        item?.approvedByCareNavigator &&
                                        <div className={styles.LName}>
                                            <h3>Care Navigator</h3>
                                            <p>{item?.approvedByCareNavigator}</p>
                                        </div>
                                    }
                                </div>
                                <div className={styles.AdBtnMain}>
                                    <button className='fillButton' onClick={() => handleAcceptCall(item)}> <AcceptIcon /> Accept</button>
                                    <button className='fillButton DeclineBtn' onClick={() => handleRejectCall(item)}><DeclineIcon /> Decline</button>
                                </div>
                            </div>
                        </div>
                    )
                })
            }

        </div>
    )
}

export default IncomingCallList