import React, { useEffect, useState } from 'react';
import CustomContainer from '../../components/common/CustomContainer';
import styles from "../../assets/styles/teams.module.css";
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { Select, MenuItem, FormGroup, FormLabel } from '@mui/material';
import moment from 'moment';
import { DeleteIcon } from '../../utils/functions/Icons';
import { addAvailabiltySlotsApi, deleteAvailabiltySlotsApi, getAvailabiltySlotsApi } from '../../utils/api/services';
import { toast } from 'react-toastify';
import Loader from '../../components/common/Loader';

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const Availability = () => {
    const [selectedDay, setSelectedDay] = useState('');
    const [weekSlots, setWeekSlots] = useState<{ [day: string]: { startTime: Date | null, endTime: Date | null }[] }>({});
    const [startTime, setStartTime] = useState<Date | null>(null);
    const [endTime, setEndTime] = useState<Date | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState(false)

    const fetchAvailability = async () => {
        try {
            setLoading(true)
            const result = await getAvailabiltySlotsApi();
            if (result?.success) {
                setLoading(false)
                const availability = result?.availability;
                const convertedData: { [key: string]: any[] } = {};
                availability.forEach((day: any) => {
                    const dayOfWeek = day.dayOfWeek;
                    const slots = day.slots.map((slot: any) => {
                        const startTime = moment(slot.startTime, 'HH:mm');
                        const endTime = moment(slot.endTime, 'HH:mm');
                        return {
                            id: slot.id,
                            startTime,
                            endTime,
                            isActive: slot.isActive
                        };
                    });
                    convertedData[dayOfWeek] = slots;
                });
                setWeekSlots(convertedData)
            }
            else {
                setLoading(false)
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.data?.status_message || error?.message);
        }
    }

    useEffect(() => {
        fetchAvailability();
    }, [])

    const getLastEndTime = (day: string) => {
        const slots = weekSlots[day] || [];
        return slots.length > 0 ? slots[slots.length - 1].endTime : null;
    };

    const handleAddSlot = () => {
        if (selectedDay && startTime && endTime) {
            if (moment(endTime).isSameOrBefore(moment(startTime))) {
                setErrorMessage('End time must be greater than start time.');
                return;
            }

            const lastEndTime = getLastEndTime(selectedDay);
            if (lastEndTime && moment(startTime).isSameOrBefore(moment(lastEndTime))) {
                setErrorMessage(`Start time must be after ${moment(lastEndTime).format('HH:mm')}.`);
                return;
            }

            const newSlot = { startTime, endTime };

            setWeekSlots({
                ...weekSlots,
                [selectedDay]: [...(weekSlots[selectedDay] || []), newSlot],
            });
            setStartTime(endTime);
            setEndTime(null);
            setErrorMessage(null);
        }
    };

    const fetchDeleteSlot = async (id: number) => {
        try {
            setLoading(true)
            const result = await deleteAvailabiltySlotsApi(id)
            if (result?.success) {
                setLoading(false)
                toast.success(result?.message)
                fetchAvailability();
            } else { setLoading(false) }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.data?.status_message || error?.message);
        }
    }

    const removeSlot = (day: string, index: number, slot: any) => {
        if (slot?.id) {
            fetchDeleteSlot(slot?.id)
            return
        }
        const updatedSlots = weekSlots[day].filter((_, i) => i !== index);
        setWeekSlots({
            ...weekSlots,
            [day]: updatedSlots,
        });

        if (updatedSlots.length > 0) {
            setStartTime(updatedSlots[updatedSlots.length - 1].endTime);
        } else {
            setStartTime(null);
        }
    };

    const handleDayChange = (day: string) => {
        setSelectedDay(day);
        setErrorMessage(null);
        const lastEndTime = getLastEndTime(day);
        setStartTime(lastEndTime);
        setEndTime(null);
    };
    const handleSubmit = async () => {
        try {
            const formattedSlots: {
                availability: { [key: string]: any };
            } = {
                availability: {}
            };

            for (const day in weekSlots) {
                formattedSlots.availability[day] = {
                    isHoliday: false,
                    slots: weekSlots[day].map(slot => ({
                        startTime: moment(slot.startTime).format('HH:mm'),
                        endTime: moment(slot.endTime).format('HH:mm')
                    }))
                };
            }
            setLoading(true)
            const result = await addAvailabiltySlotsApi(formattedSlots)
            if (result?.success) {
                setLoading(false)
                toast.success(result?.message)
                fetchAvailability();
            } else (setLoading(false))
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.data?.status_message || error?.message);
        }
    };

    if (loading) {
        return <Loader />
    }
    return (
        <CustomContainer>
            <div className='AllPageMain'>
                <div className='HeadAndButton'>
                    <h2>Availability</h2>
                </div>
                <div className={styles.availabilityMain}>
                    <div className={styles.availabilityBox}>
                        <FormGroup className="customFormGroup mb-6">
                            <FormLabel className="customFormLabel">Day <span className='errorMsg'>*</span></FormLabel>
                            <Select
                                className="custumTextField profileInput"
                                value={selectedDay}
                                name="day"
                                onChange={(e) => handleDayChange(e.target.value as string)}
                                displayEmpty
                                placeholder="Select a day"
                            >
                                <MenuItem value="">
                                    <em>Select a day</em>
                                </MenuItem>
                                {daysOfWeek.map((day) => (
                                    <MenuItem key={day} value={day}>{day}</MenuItem>
                                ))}
                            </Select>
                        </FormGroup>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Start Time <span className='errorMsg'>*</span></FormLabel>
                                <TimePicker
                                    value={startTime ? moment(startTime, 'HH:mm') : null}
                                    onChange={(date) => setStartTime(date ? moment(date).toDate() : null)}
                                    slotProps={{
                                        textField: {
                                            className: "custumTextField profileInput",
                                        },
                                    }}
                                    disabled={!selectedDay}
                                />
                            </FormGroup>

                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">End Time <span className='errorMsg'>*</span></FormLabel>
                                <TimePicker
                                    value={endTime ? moment(endTime, 'HH:mm') : null}
                                    onChange={(date) => setEndTime(date ? moment(date).toDate() : null)}
                                    slotProps={{
                                        textField: {
                                            className: "custumTextField profileInput",
                                        },
                                    }}
                                    disabled={!startTime}
                                />
                            </FormGroup>
                        </div>
                        {errorMessage && <p className="errorMsgInput mb-2">{errorMessage}</p>}

                        <button
                            onClick={handleAddSlot}
                            disabled={!selectedDay || !startTime || !endTime}
                            className="fillButton"
                        >
                            Add Slot
                        </button>
                        <div className="mt-6">
                            {Object.entries(weekSlots).map(([day, slots]) => (
                                <div key={day} className="mb-6">
                                    <h3 className="font-bold text-lg mb-2">{day}</h3>
                                    {slots.map((slot, index) => (
                                        <div key={index} className="flex items-center justify-between bg-gray-100 p-2 mb-2 rounded-md">
                                            <div>
                                                <span className="font-semibold">Start:</span> {moment(slot.startTime).format('HH:mm')} &nbsp; | &nbsp;
                                                <span className="font-semibold">End:</span> {moment(slot.endTime).format('HH:mm')}
                                            </div>
                                            <button title='Delete' onClick={() => removeSlot(day, index, slot)}>
                                                <DeleteIcon />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                        {
                            Object.values(weekSlots).some(slots => slots.length > 0) && (
                                <div className='mt-6'>
                                    <button className='fillButton' onClick={handleSubmit}>Submit</button>
                                </div>
                            )
                        }

                    </div>
                </div>
            </div>
        </CustomContainer>
    );
};

export default Availability;
